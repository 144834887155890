import React from "react";
import { MainTitleStyled } from "./MainTitle.style";

export default class MainTitle extends React.Component {
  render() {
    return (
      <MainTitleStyled {...this.props}>
        {this.props.children}
      </MainTitleStyled>
    )
  }
}
