import React from "react";
import i18n from "../../../i18n";
import PropTypes from "prop-types";
import CountdownLoaderSvg from "../../../components/CountdownLoaderSvg";
import {creativeIsNew, resolveCreativePreviewFile} from "../../../utils/creative";
import Creative from "../../../photolab/Creative";
import processingManager from "../../../photolab/ProcessingManager";
import SvgErrorIcon from "../../../ui/svg/ErrorIcon";
import {BtnStyled, LabelStyled} from "./TabView.style";
import {extraKeys, typeKeys} from "../../../photolab/etc";

export default class TabView extends React.Component {

  constructor(props) {
    super(props);

    this.tabRef = null;
    this.observer = null;
    this.touchTimer = null;
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(([entry]) => {
      this.props.onVisibilityChange && this.props.onVisibilityChange(entry.isIntersecting);
    });
    this.observer.observe(this.tabRef);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  handleTouchStart = () => {
    this.touchTimer = setTimeout(() => this.props.onLongClick(), 500);
  }

  handleTouchEnd = () => {
    clearTimeout(this.touchTimer);
  }

  render() {
    const {
      group,
      isActive,
      isPro,
      onClick,
      children,
      className,
      hidden
    } = this.props;

    if (hidden) {
      return <React.Fragment />;
    }

    const processing = processingManager.processing;

    const creative = processing.getCreativesInGroup(group)
      .find(c => c.getExtra(extraKeys.type) === typeKeys.image);

    const processingCreatives = processing.getStartedCreatives()
      .filter((c) => c.getExtra(extraKeys.type) === typeKeys.image && !c.isFinished);

    const isDisabled = !creative.hasExtra(Creative.EXTRA_STARTED_AT) && processingCreatives.length > 0;

    const isShowLoader = creative.isPending
      && creative.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true;

    const hasNewCreativesInGroup = processingManager.processing.getCreativesInGroup(group)
      .findIndex((c) => creativeIsNew(c)) !== -1;

    const style = {};
    const classNames = ["btn-choice-tab"];

    classNames.push(`btn-choice-tab--${group}`);
    isActive && classNames.push("active");
    creative && creative.isPending && classNames.push("waiting");
    creative && creative.isFailed && classNames.push("error");
    hasNewCreativesInGroup && classNames.push("new");
    className && classNames.push(className);

    if (!creative.isFailed) {
      const preview = resolveCreativePreviewFile(creative);
      if (preview) {
        style.backgroundImage = `url(${preview})`;
      }
    }

    return <BtnStyled
      ref={(ref) => this.tabRef = ref}
      onTouchStart={this.handleTouchStart}
      onTouchEnd={this.handleTouchEnd}
      disabled={isDisabled}
      className={classNames.join(" ")}
      style={style}
      onClick={onClick}
      children={children}>
      {isShowLoader && <div className="timer-loader"><CountdownLoaderSvg duration={10} /></div>}
      {isPro && <div className="label-pro">pro</div>}
      {creative && creative.isFailed && <SvgErrorIcon />}
      {hasNewCreativesInGroup && <LabelStyled dangerouslySetInnerHTML={{__html: i18n.t("label_new")}} />}
    </BtnStyled>;
  }
}

TabView.propTypes = {
  group: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
