export function isTokenSymbol(index, symbols = "") {
  let [,, token] = (window.clientConfig.token).toLowerCase().split(":");
  token = token.replace(/[^0-9abcdefxz]+/ig, "");

  symbols = symbols.toLowerCase();

  if (index < 0) {
    index = token.length - Math.abs(index);
  }

  return symbols.indexOf(token[index]) >= 0;
}

export function isWebviewBuild(android, ios) {
  const build = parseInt(window.clientConfig.webviewParams.version_code || 0);

  if (window.clientConfig.isWebviewIOS) {
    return build >= ios;
  } else if (window.clientConfig.isWebviewAndroid) {
    return build >= android;
  } else {
    return false;
  }
}

export function isWebviewCountryOf(code, onlyGeo = false) {
  code = code.toLowerCase();

  if ((window.clientConfig.webviewParams.geoip_country || "").toLowerCase() === code) {
    return true;
  }

  return onlyGeo
    ? false
    : ((window.clientConfig.webviewParams.country || "").toLowerCase() === code);
}

export function isWebviewCountriesOf(codes, onlyGeo = false) {
  codes = codes.map((code) => code.toLowerCase());
  const geoipCode = (window.clientConfig.webviewParams.geoip_country || "").toLowerCase();

  if (codes.includes(geoipCode)) {
    return true;
  }

  const countryCode = (window.clientConfig.webviewParams.country || "").toLowerCase();

  return onlyGeo
    ? false
    : codes.includes(countryCode);
}

export function isWebCountriesOf(codes) {
  codes = codes.map((code) => code.toLowerCase());
  const geoipCode = (window.clientConfig.geoipCountryCode || "").toLowerCase();

  return codes.includes(geoipCode);
}

export function isWebviewApp() {
  return window.clientConfig.webviewParams
    && Array.prototype.indexOf.call(arguments, parseInt(window.clientConfig.webviewParams.app_id)) > -1;
}