import {generatePath} from "react-router";
import routes from "../routes";
import clientStorage from "./client-storage";
import buildInfo from "./build-info";

export function goToProcessing(history, files) {
  if (window.clientConfig.isWeb) {
    history.replace({
      pathname: generatePath(routes.PROCESSING),
      state: {files},
    });

    return;
  }

  clientStorage.setLatestSelectedImages(files);

  const processingUrl = new URL(window.location.href);
  processingUrl.pathname = generatePath(routes.PROCESSING, {hash: Date.now()});
  processingUrl.search = "";

  Object.keys(window.clientConfig.webviewParams).forEach((key) => {
    processingUrl.searchParams.append(key, window.clientConfig.webviewParams[key]);
  });

  buildInfo.fetch()
    .then((result) => {
      if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
        window.location.replace(processingUrl.toString());
        // hitEvent(hits.FORCE_REDIRECT_PROCESSING);
      } else {
        buildInfo.destroy();
        history.replace({
          pathname: generatePath(routes.PROCESSING),
          state: {files},
        });
      }
    })
    .catch((err) => {
      window.location.replace(processingUrl.toString());
      // hitEvent(hits.FORCE_REDIRECT_PROCESSING);
    });
}
