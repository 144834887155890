import styled from "styled-components";

export const ParentStyled = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 16px;
  height: 30px;

  @media all and (min-width: 992px) and (orientation: landscape) {
    font-size: 24px;
    line-height: 1.67;
    position: static;
    height: 40px;
    margin-top: 40px;
  }

`;

export const TextStyled = styled.p``;

export const ButtonStyled = styled.button`
  width: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  background: none;

  svg {
      width: 18px;
      fill: #fff;
  }

  @media all and (min-width: 992px) and (orientation: landscape) {
    top: 48px;
    left: 80px;
    transform: none;

    svg {
        width: 25px;
    }
  }
`;
