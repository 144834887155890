import styled from "styled-components";
import FlexWrap from "../FlexWrap/FlexWrap";

export const AppHeaderStyled = styled(FlexWrap)`
  display:  none;
  position: absolute;
  top: 36px;
  left: 0;
  z-index: 1;

  p {
      font-family: 'Barbie';
      font-weight: bold;
      font-size: 24px;
      line-height: 1;
      margin: 0;
      color: #222;
      align-content: center;
  }

  @media (min-width: 1025px) {
    display: flex;
  }
`;

export const AppHeaderButtonStyled = styled.button`
  position: absolute;
  left: 24px;
  top: 16px;
  background: transparent;
`;
