import React from "react";
import {getInAppSku, getSubscriptionSku} from "../utils/inapp";

function initialState() {
  return {
    toast: {
      message: "",
      delay: 5000,
    },
    modals: [],
    skus: {},
    purchases: [],
    sharePromotionIsShown: false,
    sharePromotionImageUrl: null,
    sharePromotionTextKey: "result_notice_1",
    installedApps: {},
    subscriptionSku: getSubscriptionSku(),
    purchaseSku: getInAppSku(),
  };
}

const AppContext = React.createContext(initialState());

const AppContextConsumer = AppContext.Consumer;

class AppContextProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = initialState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (window.appConfig.isDebug) {
      console.log("AppContext", this.state);
    }

    if (!prevState.sharePromotionIsShown && this.state.sharePromotionIsShown) {
      clearTimeout(this.sharePromotionHideTimer);
      this.sharePromotionHideTimer = setTimeout(() => {
        this.setState({sharePromotionIsShown: false});
      }, 10000);
    }
  }

  render() {
    const state = {
      ...this.state,
      pushModal: (component, cb) => {
        this.state.modals.push(component);
        this.forceUpdate();
        cb && cb();
      },
      popModal: (cb) => {
        this.state.modals.pop();
        this.forceUpdate();
        cb && cb();
      },
      showToast: ({message, delay}) => {
        this.setState({
          toast: {
            messageKey: Date.now(),
            message,
            delay,
          },
        });
      },
      setSkus: (skus) => {
        const nextState = {
          skus: Object.keys(skus).map((sku) => {
            skus[sku].sku = sku;
            return skus[sku];
          }),
        };

        if (skus[this.state.purchaseSku.sku]) {
          nextState.purchaseSku = skus[this.state.purchaseSku.sku];
        }

        if (skus[this.state.subscriptionSku.sku]) {
          nextState.subscriptionSku = skus[this.state.subscriptionSku.sku];
        }

        this.setState(nextState);
      },
      setPurchases: (purchases, cb) => {
        this.setState({purchases}, () => cb && cb());
      },
      updatePurchase: (purchase, cb) => {
        const purchases = this.state.purchases.map((p) => {
          if (p.id === purchase.id) {
            p.available = purchase.available;
          }

          return p;
        });

        this.setState({purchases}, () => cb && cb());
      },
      setSharePromotionVisible: (flag, delay = 0, imageUrl) => {
        clearTimeout(this.sharePromotionHideTimer);
        clearTimeout(this.setSharePromotionVisibleTimer);
        this.setSharePromotionVisibleTimer = setTimeout(() => {
          this.setState({
            sharePromotionIsShown: !!flag,
            sharePromotionImageUrl: imageUrl,
            sharePromotionTextKey: [
              "result_notice_1",
              "result_notice_2",
              "result_notice_3",
              "result_notice_4",
            ].random(),
          });
        }, delay);
      },
      setInstalledApps: (installedApps) => {
        this.setState({installedApps});
      },
      setSubscriptionSku: (sku) => {
        this.setState({subscriptionSku: sku});
      },
      setPurchaseSku: (sku) => {
        this.setState({purchaseSku: sku});
      },
    };

    return <AppContext.Provider
      value={state}
      children={this.props.children}
    />;
  }
}

export default AppContext;

export {AppContextConsumer, AppContextProvider};
