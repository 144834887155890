import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";
import {isWebviewApp, isWebviewBuild} from "../../utils/config.utils";
import {webviewAppIds} from "../../utils/webview";
import { assetUrl } from "../../utils/etc";

export default class VideoButtonTextView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      provider: "none",
    }

    this.providers = [
      "none",
    ];

    this.updateProviderIndexTimer = null;
  }

  componentDidMount() {
    this.updateProviders();
    this.updateProviderIndexTimer = setInterval(this.updateProviderIndex, 800);
  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    if (this.props.providers !== nextProps.providers) {
      this.updateProviders();
    }
  }

  updateProviders = () => {
    this.providers = [
      "none",
    ];

    if (this.props.providers.instagram) {
      this.providers.push("ig");
    }

    if (this.props.providers.tiktok && !(isWebviewApp(webviewAppIds.dollme) && !isWebviewBuild(0, 63))) {
      this.providers.push("tiktok");
    }

    if (this.props.providers.snapchat && !(isWebviewApp(webviewAppIds.dollme) && window.clientConfig.isWebviewIOS)) {
      this.providers.push("snapchat");
    }
  }

  componentWillUnmount() {
    clearInterval(this.updateProviderIndexTimer);
  }

  updateProviderIndex = () => {
    if (this.state.provider === this.providers[this.providers.length - 1]) {
      this.setState({provider: "none"});
    } else {
      const index = this.providers.indexOf(this.state.provider);

      this.setState({provider: this.providers[index + 1]});
    }
  }

  render() {
    let text = i18n.t("next");

    if (this.state.provider === "tiktok") {
      text = <><img className="social-icon" src={assetUrl("assets/images/icon-tiktok.png")} alt="" /> Video</>;
    } else if (this.state.provider === "snapchat") {
      text = <><img className="social-icon" src={assetUrl("assets/images/icon-snapchat.png")} alt="" /> Story</>;
    } else if (this.state.provider === "ig") {
      text = <><img className="social-icon" src={assetUrl("assets/images/icon-insta.png")} alt="" /> Story</>;
    }

    return text;
  }
}

export function SvgIgIcon() {
  return <svg className="social-icon" viewBox="0 0 64 64">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0h64v64H0z"/>
        <g transform="translate(4.571 4.571)">
            <circle stroke="#FF62A4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" cx="27.429" cy="27.429" r="11.925"/>
            <rect stroke="#FF62A4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" width="54.857" height="54.857" rx="16"/>
            <circle fill="#FF62A4" fill-rule="nonzero" cx="43.547" cy="12.54" r="3"/>
        </g>
    </g>
  </svg>
}

export function SvgTikTokIcon() {
  return <svg className="social-icon" viewBox="0 0 64 64">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0h64v64H0z"/>
        <path d="M43.221 26.087a28.327 28.327 0 0 0 16.541 5.317V19.59c-9.135 0-16.541-7.406-16.541-16.541H31.406v37.808a8.27 8.27 0 1 1-11.815-7.473V21.095C9.124 22.972 1.934 32.68 3.19 43.24c1.256 10.559 10.524 18.31 21.139 17.677 10.614-.631 18.897-9.426 18.892-20.06V26.087z" stroke="#FF62A4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
}

export function SvgSnapchatIcon() {
  return <svg className="social-icon" viewBox="0 0 64 64">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0h64v64H0z"/>
        <g stroke="#FF62A4" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
            <path d="M3.048 48.119s14.145-6.557 14.145-28.74C17.193 11.2 23.823 4.57 32 4.57c8.178 0 14.807 6.63 14.807 14.807 0 22.184 14.145 28.741 14.145 28.741-2.459 2.3-7.667.899-10.629 2.512-2.961 1.613-4.495 6.769-7.8 7.615-3.305.846-7.086-2.909-10.523-2.909-3.437 0-7.324 3.728-10.523 2.909-3.2-.82-4.892-6.002-7.8-7.615-2.909-1.613-8.17-.212-10.63-2.512zM48.208 30.668l7.06-2.829M15.792 30.668l-7.06-2.829"/>
        </g>
    </g>
  </svg>
}

VideoButtonTextView.contextType = AppContext;
