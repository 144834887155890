import styled from "styled-components";

export const GrabContainerStyled = styled.div`
   position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  width: 70px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TrackStyled = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  height: 100%;
  width: 2px;
  background-color: #d6d6d6;
  border-radius: 2px;
`

export const ActiveTrackStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color:  #f4c500;;
  border-radius: 2px;
`

export const ContainerStyled = styled.div`
  background-color: rgba(46, 47, 53, 0.45);
  border-radius: 16px;
  width: 18px;
  height: 216px;
  position: relative;
  padding: 9px 0;
`

export const TrackContainerStyled = styled.div`
  position: absolute;
  bottom: 9px;
  left: 0;
  width: 100%;
  height: calc(100% - 18px);
`

export const ThumbStyled = styled.div`
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);

  &:focus {
    outline: none;
  }
`
