import {v4 as uuidv4} from "uuid";

export default class Creative {

  static STATUS_FAILED = -1;
  static STATUS_PENDING = 0;
  static STATUS_PROCESSED = 1;

  static EXTRA_STARTED_AT = "started_at";
  static EXTRA_FINISHED_AT = "finished_at";
  static EXTRA_COMBO_STEPS = "combo_steps";
  static EXTRA_SELECTED_AT = "selected_at";
  static EXTRA_PROCESSING_TIMEOUT = "processing_timeout";
  static EXTRA_WM_ON_DEMAND = "wm_on_demand";
  static EXTRA_ON_GENDER = "on_gender";
  static EXTRA_FIRST_DOWNLOAD_AT = "first_download_at";
  static EXTRA_WEIGHT = "weight";
  static EXTRA_VIEW_OPENED = "view_opened";
  static EXTRA_PREVIEW_URL = "preview_url";
  static EXTRA_PREVIEW_URL_FIXED = "preview_url_fixed";
  static EXTRA_KEEP_PENDING = "keep_pending";
  static EXTRA_VISIBLE_AFTER_AT = "visible_after_at";
  static EXTRA_HIDDEN_AFTER_AT = "hidden_after_at";
  static EXTRA_STATUS_NEW_DURATION = "status_new_duration";
  static EXTRA_FORCE_IS_NEW = "force_is_new";
  static EXTRA_POSITION = "position";
  static EXTRA_NAME = "name";
  static EXTRA_IS_PAYABLE = "is_payable";
  static EXTRA_IS_PAID = "is_paid";
  static EXTRA_AUTOSAVE = "autosave";

  _data = {};

  constructor() {
    this._data.id = uuidv4();
    this._data.status = Creative.STATUS_PENDING;
    this._data.group = "";
    this._data.templateId = 0;
    this._data.handler = "";
    this._data.isSelected = false;
    this._data.isRefreshed = false;
    this._data.tasks = {};
    this._data.tasksConfigs = {};
    this._data.files = {};
    this._data.error = null;
    this._data.extra = {};
    this._data.result = null;
  }

  fromObject(obj) {
    this._data = {};

    Object.keys(obj).forEach((key) => {
      this._data[key] = obj[key];
    });

    return this;
  }

  setId(id) {
    this._data.id = id;
    return this;
  }

  setTemplateId(templateId) {
    this._data.templateId = templateId;
    return this;
  }

  setGroup(templateId) {
    this._data.group = templateId;
    return this;
  }

  setHandler(handler) {
    this._data.handler = handler;
    return this;
  }

  setAsSelected(flag) {
    this._data.isSelected = !!flag;
    return this;
  }

  setAsRefreshed(flag) {
    this._data.isRefreshed = !!flag;
    return this;
  }

  markAsPending() {
    this._data.status = Creative.STATUS_PENDING;

    return this;
  }

  markAsProcessed(result) {
    this._data.result = result;
    this._data.status = Creative.STATUS_PROCESSED;
    this.setExtra(Creative.EXTRA_FINISHED_AT, Date.now());

    return this;
  }

  markAsFailed(error) {
    this._data.error = error;
    this._data.status = Creative.STATUS_FAILED;
    this.setExtra(Creative.EXTRA_FINISHED_AT, Date.now());

    return this;
  }

  getTasks() {
    return this._data.tasks;
  }

  findTasks(pattern) {
    const tasksNames = Object.keys(this._data.tasks);
    const results = {};
    tasksNames.forEach((taskName) => {
      if (pattern.test(taskName)) {
        results[taskName] = this.getTask(taskName);
      }
    })

    return results;
  }

  getTask(name) {
    return this._data.tasks[name] || null;
  }

  setTask(name, task) {
    this._data.tasks[name] = task;
    return this;
  }

  setTaskConfig(name, config) {
    this._data.tasksConfigs[name] = config;
    return this;
  }

  getTaskConfig(name) {
    return this._data.tasksConfigs[name] || null;
  }

  getFile(key) {
    return this._data.files[key] || null;
  }

  setFile(key, url) {
    this._data.files[key] = url;
    return this;
  }

  get id() {
    return this._data.id;
  }

  get group() {
    return this._data.group;
  }

  get templateId() {
    return this._data.templateId;
  }

  get handler() {
    return this._data.handler;
  }

  get isSelected() {
    return this._data.isSelected;
  }

  get isRefreshed() {
    return this._data.isRefreshed;
  }

  get isProcessed() {
    return this._data.status === Creative.STATUS_PROCESSED;
  }

  get isFinished() {
    return this._data.status === Creative.STATUS_PROCESSED
        || this._data.status === Creative.STATUS_FAILED;
  }

  get isFailed() {
    return this._data.status === Creative.STATUS_FAILED;
  }

  get isPending() {
    return this._data.status === Creative.STATUS_PENDING;
  }

  get isDummy() {
    return this._data.templateId === "dummy";
  }

  get result() {
    return this._data.result;
  }
  
  get error() {
    return this._data.error;
  }

  get data() {
    return this._data;
  }

  inGroup(group) {
    return this._data.group === group;
  }

  inGroups(groups) {
    return groups.indexOf(this._data.group) > -1;
  }

  setExtra(key, value) {
    this._data.extra[key] = value;
    return this;
  }

  hasExtra(key) {
    return this._data.extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this.hasExtra(key)
      ? this._data.extra[key]
      : defaultValue;
  }

  getExtrasCopy() {
    return JSON.parse(JSON.stringify(this._data.extra));
  }

  removeExtra(key) {
    delete this._data.extra[key];
  }
}