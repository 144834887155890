import {assetUrl} from "../utils/etc";

export default [
  {
    id: "1",
    angle: 0,
    path: "M298,0V1080h782V0Z",
    imageUrl: assetUrl("assets/effects/overlays/1.png"),
    range: [-0.15, 0.6],
    startOffset: 0,
  },
  {
    id: "22",
    angle: 0,
    path: "M353.2,1080c260.6,0,466.2,0,726.8,0c0-360,0-720,0-1080C819.4,0,619.5,0.3,353.2,0  C600.9,552.6,147,576.3,353.2,1080z",
    imageUrl: assetUrl("assets/effects/overlays/22.png"),
    range: [-0.15, 0.5],
    startOffset: 0.135,
  },
  {
    id: "8",
    angle: 0,
    path: "M349.7,1079.9c6.1-7.3,8.4-15.9-4.5-15.9c5.2,0-3.5-30.8,7.5-30.8c-5.6,0,17.7-24.5,20.2-24.5  c-6.7,0,8.8-30.5,0.9-30.5c13.8,0,16.2-26.5,19-26.5c0.3,0,1.8-26-5.8-26c8.7,0,5.5-29.1,7.9-29.1c-8.3,0,1.9-30.3,7.2-30.3  c2.1,0-24.8-30.1-22.5-30.1c7.4,0,13.8-31,11-31c-1.1,0-13.6-37.6-9.5-37.6c8.4,0,4.1-25.1,1.8-25.1c-1.4,0,26.5-18.2,31.7-18.2  c6.2,0-0.8-22.9,5.5-22.9c-1.9,0,12.7-26.6,5.1-26.6c-2.7,0,12.5-18.3,9.8-18.3c5,0-0.1-29.3-6.1-29.3c-3.2,0,2.6-28.2,6.5-28.2  c0.1,0-5.1-23.7-6.1-23.7c-4.4,0-5.6-29.1-5.2-29.1c0.7,0-3.1-22.3-12-22.3c9.2,0-16.5-19.5-12.8-19.5c-2,0-2.1-28-7.8-28  c-3.8,0,1.3-25.2,5.2-25.2c-6.9,0-35.4-27.9-27.7-27.9c-3.6,0-3.6-23.3-3.8-23.3c2.6,0,6.6-25.9,9.6-25.9  c-7.6,0-11.9-27.4-10.1-27.4c0.9,0-27.6-25.4-24.9-25.4c6,0-12.2-25.2-16.8-25.2c1.7,0-3.5-27.9,0-27.9c-1,0,0.5-29.6-3.1-29.6  c4.9,0-14-28.3-10.5-28.3c-1.7,0-13.3-30.4-18.4-30.4c2.2,0,24.8-29,10.8-29c-1.5,0,5.8-29.7,6.5-29.7c-6.3,0-5.9-27.3-4.3-27.3  c2.9,0-4.6-38.6,0.2-38.6c4.5,0,21.2-20.2,21-20.2c-13.1,0,21-27.6,12-27.6c-0.6,0-2-3.2-3.3-7.5H1080v1079.9H349.7z",
    imageUrl: assetUrl("assets/effects/overlays/8-1.png"),
    range: [-0.2, 0.5],
    startOffset: 0.125,
  },
  {
    id: "30",
    angle: 180,
    path: "M0,0H392L719,1080H0Z",
    imageUrl: assetUrl("assets/effects/overlays/30.png"),
    range: [-0.3, 0.3],
  },
  {
    id: "23",
    angle: 0,
    path: "M298,0v1080h782V0H298z",
    imageUrl: assetUrl("assets/effects/overlays/23.png"),
    range: [-0.15, 0.6],
    startOffset: 0.21,
  },
  {
    id: "19",
    angle: 0,
    path: "M615.7,0H1080l0,1080H225l106-207.5l-69.7,6.7l128.5-222.8l-89.5,25.8l240.6-398.1L425,319.6L615.7,0z",
    imageUrl: assetUrl("assets/effects/overlays/19-1.png"),
    range: [-0.1, 0.3],
    startOffset: 0.075,
  },
  {
    id: "42",
    angle: 90,
    path: "M0,711h1080v369H0V0z",
    imageUrl: assetUrl("assets/effects/overlays/42-1.png"),
    range: [-0.6, 0.27],
    startOffset: 0.1,
  },
];