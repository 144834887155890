import {promisifyImage} from "./image";
import * as api from "./api";
import {transformToDownloadUrl} from "./creative";
import {visibilitySensitiveTimeoutPromise} from "./timers";

export default (imageUrl, watermarkConfig, stickerConfig) => {
  const proxiedImageUrl = new URL(transformToDownloadUrl(imageUrl));
  proxiedImageUrl.searchParams.append("rc", "" + Date.now());

  const images = [
    promisifyImage(proxiedImageUrl.toString(), true),
  ];

  if (watermarkConfig) {
    images.push(promisifyImage(watermarkConfig.url, true));
  } else {
    images.push(Promise.resolve());
  }

  if (stickerConfig) {
    const proxiedStickerUrl = new URL(transformToDownloadUrl(stickerConfig.url));
    proxiedStickerUrl.searchParams.append("rc", "" + Date.now());
    images.push(promisifyImage(proxiedStickerUrl.toString(), true));
  }

  return Promise.race([
    visibilitySensitiveTimeoutPromise(10_000),
    Promise.all(images),
  ]).then(([sourceImage, watermarkImage, stickerImage]) => {
    const canvas = document.createElement("canvas");
    canvas.width = sourceImage.width;
    canvas.height = sourceImage.height;

    const canvasCtx = canvas.getContext("2d");
    canvasCtx.drawImage(sourceImage, 0, 0);

    if (watermarkImage) {
      const watermarkWidth = Math.round(canvas.width / 100 * watermarkConfig.percentage);
      const watermarkHeight = watermarkWidth * watermarkImage.height / watermarkImage.width;
      const watermarkPosX = canvas.width - (watermarkWidth + Math.round(watermarkConfig.x * (canvas.width / 100)));
      const watermarkPosY = canvas.height - (watermarkHeight + Math.round(watermarkConfig.y * (canvas.height / 100)));
      canvasCtx.drawImage(watermarkImage, watermarkPosX, watermarkPosY, watermarkWidth, watermarkHeight);
    }

    if (stickerImage) {
      const stickerInnerPadding = 8;
      const stickerWidth = Math.round(canvas.width * stickerConfig.size);
      const stickerHeight = stickerWidth * stickerImage.height / stickerImage.width;
      const stickerPosX = Math.round(stickerConfig.x * canvas.width);
      const stickerPosY = (canvas.height - Math.round(stickerConfig.y * canvas.height)) - stickerHeight;
      const hw = stickerWidth/2;
      const hh = stickerHeight/2;

      canvasCtx.translate(stickerPosX + hw, stickerPosY + hh);
      canvasCtx.rotate((-3 * Math.PI) / 180);
      canvasCtx.fillStyle = "white";
      canvasCtx.fillRect(-hw, -hh, stickerWidth, stickerHeight);
      canvasCtx.drawImage(
        stickerImage,
        stickerInnerPadding - hw,
        stickerInnerPadding - hh,
        stickerWidth-stickerInnerPadding*2,
        stickerHeight-stickerInnerPadding*2
      );
      canvasCtx.setTransform(1, 0, 0, 1, 0, 0);
    }

    return canvas;
  }).then((canvas) => {
    // const extension = imageUrl
    //   .split(".")
    //   .pop()
    //   .toLowerCase()
    //   .replace(/jpg/, "jpeg");

    return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg"));
  }).then((blob) => {
    return api.tempImagesUploadFile(blob, "jpeg");
  });
}

