import React, {Component} from 'react';
import AppContext from "../contexts/AppContext";

export default class Modal extends Component {

  constructor(props) {
    super(props);

    this.overlayRef = React.createRef();

    this.dismiss = this.dismiss.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
  }

  dismiss(trigger) {
    if (this.props.onDismiss) {
      if (this.props.onDismiss(this.state, trigger) !== false) {
        this.context.popModal(() => {
          this.props.onDismissed && this.props.onDismissed(this.state, trigger);
        });
      }
    } else {
      this.context.popModal(() => {
        this.props.onDismissed && this.props.onDismissed(this.state, trigger);
      });
    }
  }

  handleOverlayClick(e) {
    if (e.target === this.overlayRef.current) {
      // if (this.props.closeByClick) {
        this.dismiss();
      // }
    }
  }

  handleCloseButtonClick(e) {
    this.dismiss();
  }

  renderModal() {
    return <React.Fragment />;
  }

  render() {
    return <div
      ref={this.overlayRef}
      className={`popup-overlay ${this.props.className || ""} ${this.className || ""}`}
      onClick={this.handleOverlayClick}>

      <button
        hidden={this.props.hideCloseButton}
        className="button-close-popup"
        onClick={this.handleCloseButtonClick}>
        <svg viewBox="0 0 16 16">
          <path strokeWidth=".5" d="M.554 2.025L6.53 8 .554 13.976c-.405.405-.405 1.064 0 1.47.406.405 1.065.405 1.47 0L8 9.47l5.975 5.975c.406.406 1.065.406 1.47 0h0c.406-.405.406-1.064 0-1.47L9.47 8l5.976-5.976h0c.405-.405.405-1.064 0-1.47-.406-.405-1.065-.405-1.47 0 0 0 0 0 0 0L8 6.53 2.024.554C1.62.15.96.15.554.554.15.96.148 1.62.554 2.024c0 0 0 0 0 0z"/>
        </svg>
      </button>

      <div
        className={`popup ${window.clientConfig.isWeb ? "web" : ""}`}
        children={this.renderModal()} />

    </div>;
  }
}

Modal.contextType = AppContext;
