export function promisifyImage(url, crossOrigin) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    crossOrigin && (image.crossOrigin = "anonymous");
    image.onload = () => resolve(image);
    image.onerror = () => reject(new Error("Failed to load image: " + url));
    image.src = url;
  });
}

export function containRectToBounds(rect, bounds) {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const box = {};

  if (rectRatio > boundsRatio) {
    box.width = bounds.width;
    box.height = rect.height * (bounds.width / rect.width);
  } else {
    box.width = rect.width * (bounds.height / rect.height);
    box.height = bounds.height;
  }

  box.x = (bounds.width - box.width) / 2;
  box.y = (bounds.height - box.height) / 2;

  return box;
}

export function coverRectToBounds(rect, bounds) {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const box = {};

  if (rectRatio < boundsRatio) {
    box.width = bounds.width;
    box.height = rect.height * (bounds.width / rect.width);
  } else {
    box.width = rect.width * (bounds.height / rect.height);
    box.height = bounds.height;
  }

  box.x = (bounds.width - box.width) / 2;
  box.y = (bounds.height - box.height) / 2;

  return box;
}

export function trimCanvas(canvas) {
  const context = canvas.getContext("2d");
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

  const topLeft = {
    x: canvas.width,
    y: canvas.height,
    update(x, y) {
      this.x = Math.min(this.x, x);
      this.y = Math.min(this.y, y);
    }
  };

  const bottomRight = {
    x: 0,
    y: 0,
    update(x, y) {
      this.x = Math.max(this.x, x);
      this.y = Math.max(this.y, y);
    }
  };

  for (let x = 0; x < canvas.width; x++) {
    for (let y = 0; y < canvas.height; y++) {
      const alpha = imageData.data[((y * (canvas.width * 4)) + (x * 4)) + 3];
      if (alpha !== 0) {
        topLeft.update(x, y);
        bottomRight.update(x, y);
      }
    }
  }

  const width = bottomRight.x - topLeft.x;
  const height = bottomRight.y - topLeft.y;
  const cropImageData = context.getImageData(topLeft.x, topLeft.y, width, height);
  canvas.width = width;
  canvas.height = height;
  context.putImageData(cropImageData, 0, 0);

  return canvas;
}