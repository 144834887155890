import React from "react";
import { BackButtonStyled } from "./BackButton.style";
import routes from "../../routes";

export default class BackButton extends React.Component {
  render() {
    return (
      <BackButtonStyled to={routes.INDEX} {...this.props}>
        <svg viewBox="0 0 25 12" fill="none">
          <path d="M.47 5.47a.75.75 0 0 0 0 1.06l4.773 4.773a.75.75 0 1 0 1.06-1.06L2.061 6l4.242-4.243a.75.75 0 0 0-1.06-1.06L.47 5.47zM1 6.75h24v-1.5H1v1.5z" fill="#111"/>
        </svg>
      </BackButtonStyled>
    )
  }
}
