import React from "react";
import { ButtonStyled } from "./Button.style";

export default class Button extends React.Component {
    render() {
        if (this.props.hidden) {
            return <React.Fragment />;
        }

        return (
            <ButtonStyled {...this.props}>{this.props.children}</ButtonStyled>
        )
    }
}
