import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
  max-width: 730px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 16px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 0;
  margin: 0 auto 10px;
`

export const WrapperContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  overflow: auto;
  max-width: max-content;
  width: 100%;
  padding-bottom: 6px;

  &::-webkit-scrollbar-track {
    background-color: transparent;
    height: 0;
    border-radius: 8px;
  }

  &::-webkit-scrollbar {
    padding: 10px 0;
    height: 0;
    border-radius: 8px;
    background-color:  #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color:  #fff;
    border-radius: 8px;
  }

  /* @media all and (max-height: 1050px) and (orientation: landscape) {
    max-width: 550px;
  } */

  @media all and (max-height: 640px) and (orientation: landscape) {
    margin-bottom: 16px;
  }
`
