import React from "react";
import {ParentStyled, ButtonStyled, TextStyled} from "./Header.style"
import i18n from "../../i18n";

export default class Header extends React.Component {
  render() {
    return <ParentStyled hidden={window.clientConfig.isWebview}>
      <ButtonStyled onClick={this.props.onHandleClick}>
        <SvgBackButtonIcon />
      </ButtonStyled>
      <TextStyled>{i18n.t(`${this.props.title}_title`)}</TextStyled>
    </ParentStyled>;
  }
}

function SvgBackButtonIcon() {
  return <svg viewBox="0 0 25 16" fill="none">
    <path d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293zM1 9h24V7H1v2z" fill="#fff" />
  </svg>;
}
