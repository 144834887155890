export const genderKeys = {
  male: "male",
  female: "female",
};

export const extraKeys = {
  version: "version",
  gender: "gender",
  type: "type",
  withSound: "with_sound",
};

export const typeKeys = {
  video: "video",
  image: "image",
}
