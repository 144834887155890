import Creative from "../Creative";
import {handlersNames} from "../handlers";
import {extraKeys, genderKeys, typeKeys} from "../etc";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAppIds} from "../../utils/webview";
import {genders} from "./helpers";

export const configBuilders = {
  simple8866: "simple8866",
  barbifyAiOld: "barbify_ai_old",
  simple8872: "simple8872",
  barbieAiBody: "barbie_ai_body"
};

export function getPrompts(gender) {
  return [
    // мужские
    {id: "wt_d19wc_barbie_male", genders: [genderKeys.male], builder: configBuilders.simple8872},
    {id: "wt_d19_barbie_male_7", genders: [genderKeys.male], builder: configBuilders.simple8866},
    {id: "wt_d19wc_barbie_male_style", genders: [genderKeys.male], builder: configBuilders.simple8872},
    {id: "wt_d19_barbie_male_6", genders: [genderKeys.male], builder: configBuilders.simple8866},
    {id: "wt_d19_barbie_male_2", genders: [genderKeys.male], builder: configBuilders.simple8866},
    {id: "wt_d19_barbie_male_5", genders: [genderKeys.male], builder: configBuilders.simple8866},
    {id: "wt_d19_barbie_male_8", genders: [genderKeys.male], builder: configBuilders.simple8866},

    // женские
    {id: "wt_d19_barbie_female_6", genders: [genderKeys.female], builder: configBuilders.simple8866},
    {id: "wt_d19_barbie_female_5", genders: [genderKeys.female], builder: configBuilders.simple8866},
    {id: "wt_d19_barbie_female_2", genders: [genderKeys.female], builder: configBuilders.simple8866},
    {id: "wt_d19_barbie_female_9", genders: [genderKeys.female], builder: configBuilders.simple8866},
    {id: "wt_d19_barbie_female_4", genders: [genderKeys.female], builder: configBuilders.simple8866},
    {id: "wt_d19wc_barbie_female_1", genders: [genderKeys.female], builder: configBuilders.simple8872},
    {id: "wt_d19wc_barbie_female_2", genders: [genderKeys.female], builder: configBuilders.simple8872},
  ].filter((item) => {
    return item.genders.indexOf(gender) >= 0;
  });
}

export function getBarbieAiBodyPrompts() {
  return [
    {
      id: "m_barbie_ai_body",
      templateId: "m_barbie_ai_body",
      genders: [genderKeys.male],
      builder: configBuilders.barbieAiBody,
      template_name: 8866
    },
    {
      id: "m_barbie_ai_body",
      templateId: "m_barbie_ai_body_tropical_beach",
      genders: [genderKeys.male],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "m_barbie_ai_body",
      templateId: "m_barbie_ai_body_garage",
      genders: [genderKeys.male],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "m_barbie_ai_body",
      templateId: "m_barbie_ai_body_park",
      genders: [genderKeys.male],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "m_barbie_ai_body",
      templateId: "m_barbie_ai_body_kitchen",
      genders: [genderKeys.male],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "m_barbie_ai_body",
      templateId: "m_barbie_ai_body_street_cars",
      genders: [genderKeys.male],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "barbie_ai_body",
      templateId: "barbie_ai_body",
      genders: [genderKeys.female],
      builder: configBuilders.barbieAiBody,
      template_name: 8866
    },
    {
      id: "barbie_ai_body",
      templateId: "barbie_ai_body_waterfall_tourism",
      genders: [genderKeys.female],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "barbie_ai_body",
      templateId: "barbie_ai_body_autumn_puppy",
      genders: [genderKeys.female],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "barbie_ai_body",
      templateId: "barbie_ai_body_pool",
      genders: [genderKeys.female],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "barbie_ai_body",
      templateId: "barbie_ai_body_restaurant",
      genders: [genderKeys.female],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "barbie_ai_body",
      templateId: "barbie_ai_body_colorful_office",
      genders: [genderKeys.female],
      builder: configBuilders.barbieAiBody,
    },
    {
      id: "barbie_ai_body",
      templateId: "barbie_ai_body_office",
      genders: [genderKeys.female],
      builder: configBuilders.barbieAiBody,
    },
  ]
}

export function createCreativesByPromptConfig(processing, config, context) {
  const imageSteps = getSteps(config, context);
  const imageCreative = new Creative();
  imageCreative.setGroup(config.id);
  imageCreative.setTemplateId(config.templateId || imageSteps.map((step) => step.id).join("_"));
  imageCreative.setHandler(handlersNames.COMBO);
  imageCreative.setExtra(Creative.EXTRA_COMBO_STEPS, imageSteps);
  imageCreative.setExtra(Creative.EXTRA_KEEP_PENDING, true);
  imageCreative.setExtra(extraKeys.gender, context.gender);
  imageCreative.setExtra(extraKeys.type, typeKeys.image);
  imageCreative.setAsSelected(true);

  if (config.builder === configBuilders.barbieAiBody) {
    imageCreative.setExtra("seed", imageSteps[1].templateParams.param_sd);
  }

  let videoId = 8875;

  if (!window.clientConfig.isPro) {
    if (isWebviewApp(webviewAppIds.dollme) || window.clientConfig.isWeb) {
      videoId = 8897;
    } else if (isWebviewApp(webviewAppIds.photolabFree, webviewAppIds.photolabHuawei)) {
      videoId = 8877;
    } else if (isWebviewApp(webviewAppIds.toonme, webviewAppIds.toonmeHuawei)) {
      videoId = 8878;
    }
  }

  const videoSteps = JSON.parse(JSON.stringify(imageSteps));
  delete videoSteps.last().setAsFile;
  videoSteps.push({id: videoId, setAsFile: "raw"});

  const videoCreative = new Creative();
  videoCreative.setGroup(config.id);
  videoCreative.setTemplateId(videoSteps.map((step) => step.id).join("_"));
  videoCreative.setHandler(handlersNames.COMBO);
  videoCreative.setExtra(Creative.EXTRA_COMBO_STEPS, videoSteps);
  videoCreative.setExtra(Creative.EXTRA_KEEP_PENDING, true);
  videoCreative.setExtra(extraKeys.gender, context.gender);
  videoCreative.setExtra(extraKeys.type, typeKeys.video);
  videoCreative.setExtra(extraKeys.withSound, true);

  return [imageCreative, videoCreative];
}

function getSteps(config, context) {
  if (config.builder === configBuilders.simple8866 || config.builder === configBuilders.simple8872) {
    return getStepsForDefault(config, context);
  } else if (config.builder === configBuilders.barbifyAiOld) {
    return getStepsForBarbifyAiOld(config, context);
  } else if (config.builder === configBuilders.barbieAiBody) {
    return getStepsForBarbifyAiBody(config);
  } else {
    throw new Error("Unrecognized config builder: " + config.builder);
  }
}

function getStepsForDefault(config, context) {
  const templateGender = context.gender
    .replace("female", "woman")
    .replace("male", "man");

  return [
    {
      id: config.builder === configBuilders.simple8872 ? 8872 : 8866,
      images: [{src: "@", useAltBody: true}],
      templateParams: {
        "@configId": config.configId || config.id,
        gender: templateGender,
      },
      setAsFile: "raw",
    },
  ];
}

function getStepsForBarbifyAiOld(config, context) {
  const templateGender = context.gender
    .replace("female", "woman")
    .replace("male", "man");

  const faceSteps = [];
  const templateParams = {};

  if (context.gender === genders.male) {
    faceSteps.push({id: 7088});
    templateParams["@replacements"] = [{
      parameters: ["prompt"],
      search: "barbie",
      mode: "ig",
      replacement: "Ken",
    }];
  } else {
    faceSteps.push({id: 6472}, {id: 8666});
  }

  return [
    {
      id: 8205,
      images: [{src: "@", useAltBody: true}],
    },
    ...faceSteps,
    {
      id: 8681,
      templateParams: {
        ...templateParams,
        "@transformationId": "barbify_old",
        "@configId": config.configId || config.id,
        canvas_template_name: 8659,
        mask_template_name: 8660,
        param_ipfl: 1,
        param_gm: "TRUE",
        param_s: 0.4,
        gender: templateGender,
      },
      setAsFile: "raw",
    },
  ];
}

function getStepsForBarbifyAiBody(config) {
  return [
    {
      id: 8809,
      templateParams: {
        questions: '[|What gender is this person?|, |What hair color this person has?|, |What color of human skin this person has?|]'
      },
      images: [{src: "@", useAltBody: true}],
    },
    {
      answers: true,
      id: config.template_name ? config.template_name : 8905,
      templateParams: {
        "@transformationId": "dollme_ai_body",
        "@configId": config.templateId,
        param_sd: getRandomSd()
      },
      images: [{src: "@", useAltBody: true}],
    },
    {
      id: 8896,
      images: [{src: "@"}, {src: "#" + 1}],
      setAsFile: "raw",
    }
  ];
}

export const getRandomSd = () => {
  const min = 1000;
  const max = 2_000_000;
  return Math.floor(Math.random() * (max - min)) + min;
}