import React from "react";
import {containRectToBounds, coverRectToBounds, promisifyImage, trimCanvas} from "../../utils/image";
import * as View from "./styles";
import PropTypes from "prop-types";
import BezierEasing from "bezier-easing";

const redrawOnChangeFields = [
  "imagesOffset",
  "sourceIsFlip",
  "resultIsFlip",
];

export default class ComparatorView extends React.Component {

  state = {
    isReady: false,
    position: {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    },
  };

  holderRef = null;
  canvasRef = null;
  touches = [];
  effectMaskCanvas = document.createElement("canvas");
  effectImageCanvas = document.createElement("canvas");
  effectOffset = 0;
  effectAnimationEasing = BezierEasing(.42, 0, .58, 1);
  leftArrowCanvas = document.createElement("canvas");
  rightArrowCanvas = document.createElement("canvas");
  upArrowCanvas = document.createElement("canvas");
  downArrowCanvas = document.createElement("canvas");
  pixelRatio = window.devicePixelRatio || 1;

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResizeChanged);
    this.holderRef.addEventListener("mouseup", this.handleMouseUp);
    this.holderRef.addEventListener("mousedown", this.handleMouseDown);
    this.holderRef.addEventListener("mousemove", this.handleMouseMove);
    this.holderRef.addEventListener("touchstart", this.handleTouchStart, {passive: false});
    this.holderRef.addEventListener("touchend", this.handleTouchEnd, {passive: false});
    this.holderRef.addEventListener("touchmove", this.handleTouchMove, {passive: false});
    this.load();

    this.props.onGetResultCanvasFunc(() => {
      const canvas = document.createElement("canvas");
      canvas.width = this.resultImage.width * this.pixelRatio;
      canvas.height = this.resultImage.height * this.pixelRatio;
      this.drawOnCanvas(canvas);
      trimCanvas(canvas);

      return [canvas, this.props.effect, this.effectOffset];
    });
  }

  componentWillUnmount() {
    this.stopEffectAnimation();

    window.removeEventListener("resize", this.handleWindowResizeChanged);
    this.holderRef.removeEventListener("mouseup", this.handleMouseUp);
    this.holderRef.removeEventListener("mousedown", this.handleMouseDown);
    this.holderRef.removeEventListener("mousemove", this.handleMouseMove);
    this.holderRef.removeEventListener("touchstart", this.handleTouchStart);
    this.holderRef.removeEventListener("touchend", this.handleTouchEnd);
    this.holderRef.removeEventListener("touchmove", this.handleTouchMove);
    this.props.onGetResultCanvasFunc(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.effect.id !== this.props.effect.id) {
      this.setState({isReady: false});
      this.loadEffect(this.props.effect);
    }

    if (redrawOnChangeFields.some((key) => prevProps[key] !== this.props[key])) {
      this.correctEffectOffset();
      this.redraw();
    }

    if (this.state.isReady !== prevState.isReady) {
      this.props.onReadyStateChanged && this.props.onReadyStateChanged(this.state.isReady);
      if (this.state.isReady) {
        this.redraw();
      }
    }
  }

  load = () => {
    Promise.all([
      promisifyImage(this.props.sourceImageUrl, true),
      promisifyImage(this.props.resultImageUrl, true),
    ]).then(([sourceImage, resultImage]) => {
      this.sourceImage = sourceImage;
      this.resultImage = resultImage;
      this.prepareArrows();
      this.loadEffect(this.props.effect);
    });
  };

  loadEffect = (effect) => {
    promisifyImage(effect.imageUrl, true).then((effectImage) => {
      if (effect.id !== this.props.effect.id) {
        return;
      }

      this.effectImage = effectImage;
      this.effectOffset = effect.startOffset || 0;
      this.correctEffectOffset();
      this.prepareEffectMaskCanvas();
      this.prepareEffectImageCanvas();

      if (this.props.effectAnimateOnLoad) {
        this.startEffectAnimation();
      }

      this.setState({isReady: true});
    })
  };

  startEffectAnimation = () => {
    const maxTimes = (this.props.effectAnimationRepeat || 1) * 2;
    let times = 0;
    let duration = (this.props.effectAnimationDuration || 1000) / 2;
    let startAt = Date.now();
    let endAt = startAt + duration;
    let startValue = this.effectOffset;
    let isGrow = true;

    clearInterval(this._effectAnimationTimer);
    this._effectAnimationTimer = setInterval(() => {
      const now = Date.now();
      const p = (now - startAt) / (endAt - startAt);
      const pe = this.effectAnimationEasing(p);

      if (isGrow) {
        this.effectOffset = startValue + (this.props.effectAnimationStrength * pe);
      } else {
        this.effectOffset = startValue - (this.props.effectAnimationStrength * pe);
      }

      this.redraw();

      if (p >= 1) {
        isGrow = !isGrow;
        startAt = now;
        endAt = startAt + (this.props.effectAnimationDuration || 1000);
        startValue = this.effectOffset;
        times++;
      }

      if (times >= maxTimes) {
        this.stopEffectAnimation();
      }
    }, 1000/60);
  };

  stopEffectAnimation = () => {
    clearInterval(this._effectAnimationTimer);
  };

  prepareArrows = () => {
    const cw = 16 * this.pixelRatio;
    const ch = cw;
    const aw = 3 * this.pixelRatio;
    const ah = 6 * this.pixelRatio;

    this.leftArrowCanvas.width = cw;
    this.leftArrowCanvas.height = ch;
    this.rightArrowCanvas.width = cw;
    this.rightArrowCanvas.height = ch;
    this.upArrowCanvas.width = ch;
    this.upArrowCanvas.height = cw;
    this.downArrowCanvas.width = ch;
    this.downArrowCanvas.height = cw;

    const ctx = this.leftArrowCanvas.getContext("2d");

    ctx.save();
    ctx.beginPath();

    // на ios safari ниже 16 будет фон без закруглений
    if (typeof ctx.roundRect === "function") {
      ctx.roundRect(0, 0, cw, ch, [32, 8, 8, 32]);
    } else {
      ctx.rect(0, 0, cw, ch);
    }

    ctx.fillStyle = "rgba(45, 47, 53, 0.45)";
    ctx.fill();
    ctx.restore();

    ctx.save();
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.lineWidth = 3;
    ctx.strokeStyle = "white";
    ctx.beginPath();
    ctx.moveTo(cw/2 + aw, ch/2-ah/2);
    ctx.lineTo(cw/2 - aw/2, ch/2);
    ctx.lineTo(cw/2 + aw, ch/2 + ah/2);
    ctx.stroke();
    ctx.restore();

    const rightCtx = this.rightArrowCanvas.getContext("2d");
    rightCtx.scale(-1, 1);
    rightCtx.drawImage(this.leftArrowCanvas, -cw, 0);
    rightCtx.scale(1, 1);

    const upCtx = this.upArrowCanvas.getContext("2d");
    upCtx.translate(cw/2, ch/2);
    upCtx.rotate((90 * Math.PI) / 180);
    upCtx.drawImage(this.leftArrowCanvas, -cw/2, -ch/2);

    const downCtx = this.downArrowCanvas.getContext("2d");
    downCtx.translate(cw/2, ch/2);
    downCtx.rotate((-90 * Math.PI) / 180);
    downCtx.drawImage(this.leftArrowCanvas, -cw/2, -ch/2);
  };

  prepareEffectMaskCanvas = () => {
    const ctx = this.effectMaskCanvas.getContext("2d");
    const w = this.effectImage.width;
    const h = this.effectImage.height;

    ctx.clearRect(0, 0, this.effectMaskCanvas.width, this.effectMaskCanvas.height);

    this.effectMaskCanvas.width = w * 3;
    this.effectMaskCanvas.height = h * 3;

    ctx.translate(w, h);
    ctx.fillStyle = "black";
    ctx.fill(new Path2D(this.props.effect.path));
    ctx.translate(-w, -h);

    const arrowsEdgeData = this.isVerticalEffect()
      ? ctx.getImageData(w + Math.round(w * 0.75), h, 1, h)
      : ctx.getImageData(w, h + Math.round(h * 0.75), w, 1);
    const firstPixelAlpha = arrowsEdgeData.data[3];
    for (let i = 0; i < arrowsEdgeData.data.length; i += 4) {
      if (firstPixelAlpha !== arrowsEdgeData.data[i + 3]) {
        this.effectEdgeForArrows = (i / 4) / w;
        break;
      }
    }

    // top
    ctx.drawImage(
      this.effectMaskCanvas,
      w, h + 1, w, 1,
      w, 0, w, h
    );

    // bottom
    ctx.drawImage(
      this.effectMaskCanvas,
      w, h * 2 - 2, w, 1,
      w, h * 2, w, h
    );

    // left
    ctx.drawImage(
      this.effectMaskCanvas,
      w + 1, h, 1, h,
      0, h, w, h
    );

    // right
    ctx.drawImage(
      this.effectMaskCanvas,
      w * 2 - 2, h, 1, h,
      w * 2, h, w, h
    );
  }

  prepareEffectImageCanvas = () => {
    const ctx = this.effectImageCanvas.getContext("2d");
    const w = this.effectImage.width;
    const h = this.effectImage.height;

    ctx.clearRect(0, 0, this.effectImageCanvas.width, this.effectImageCanvas.height);

    this.effectImageCanvas.width = w * 3;
    this.effectImageCanvas.height = h * 3;

    ctx.drawImage(this.effectImage, w, h);

    // top
    ctx.drawImage(
      this.effectImageCanvas,
      w, h + 1, w, 1,
      w, 0, w, h
    );

    // bottom
    ctx.drawImage(
      this.effectImageCanvas,
      w, h * 2 - 2, w, 1,
      w, h * 2, w, h
    );

    // left
    ctx.drawImage(
      this.effectImageCanvas,
      w + 1, h, 1, h,
      0, h, w, h
    );

    // right
    ctx.drawImage(
      this.effectImageCanvas,
      w * 2 - 2, h, 1, h,
      w * 2, h, w, h
    );
  };

  handleWindowResizeChanged = () => {
    if (this.state.isReady) {
      this.redraw();
    }
  };

  handleMouseUp = (e) => {
    this.stopEffectAnimation();
    this.isMouseDown = this.isMouseDown && e.button !== 0;
  };

  handleMouseDown = (e) => {
    this.stopEffectAnimation();
    this.isMouseDown = e.button === 0;
    this.lastMouseX = e.clientX;
    this.lastMouseY = e.clientY;
  };

  handleMouseMove = (e) => {
    this.stopEffectAnimation();
    if (!this.isMouseDown || !this.state.isReady) {
      return;
    }

    this.move(e.clientX - this.lastMouseX, e.clientY - this.lastMouseY);
    this.lastMouseX = e.clientX;
    this.lastMouseY = e.clientY;
  };

  handleTouchStart = (e) => {
    e.preventDefault();
    this.stopEffectAnimation();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i];
      const x = touch.clientX;
      const y = touch.clientY;

      this.touches.push({
        id: touch.identifier,
        x: x,
        y: y,
        px: x,
        py: y,
        sx: x,
        sy: y,
      });
    }
  }

  handleTouchEnd = (e) => {
    e.preventDefault();
    this.stopEffectAnimation();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i];
      const index = this.touches.findIndex((t) => t.id === touch.identifier);
      if (index >= 0) {
        this.touches.splice(index, 1);
      }
    }
  };

  handleTouchMove = (e) => {
    e.preventDefault();
    this.stopEffectAnimation();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = this.touches.find((t) => t.id === e.changedTouches[i].identifier);
      if (touch != null) {
        touch.px = touch.x;
        touch.py = touch.y;
        touch.x = e.changedTouches[i].clientX;
        touch.y = e.changedTouches[i].clientY;
      }
    }

    if (this.touches.length === 1 && this.state.isReady) {
      const touch = this.touches.first();
      this.move(touch.x - touch.px, touch.y - touch.py);
    }
  };

  isVerticalEffect = () => {
    return this.props.effect.angle === 270 || this.props.effect.angle === 90;
  };

  isInvertedEffect = () => {
    return this.props.effect.angle === 180;
  };

  move = (dx, dy) => {
    if (this.isVerticalEffect()) {
      this.effectOffset += (dy / this.bounds.height);
    } else {
      this.effectOffset += (dx / this.bounds.width);
    }

    this.correctEffectOffset();
    this.redraw();

    this.props.onEffectOffsetChanged && this.props.onEffectOffsetChanged(this.effectOffset);
  };

  correctEffectOffset = () => {
    const min = this.props.effect.range[0] + this.props.imagesOffset;
    const max = this.props.effect.range[1] - this.props.imagesOffset;
    this.effectOffset = Math.max(this.effectOffset, min);
    this.effectOffset = Math.min(this.effectOffset, max);
  };

  redraw = () => {
    cancelAnimationFrame(this.drawRequestId);
    this.drawRequestId = requestAnimationFrame(this.draw);
  };

  draw = () => {
    if (!this.holderRef || !this.resultImage || !this.sourceImage) {
      return;
    }

    const holderRect = this.holderRef.getBoundingClientRect();

    this.bounds = containRectToBounds(this.resultImage, holderRect);
    this.canvasRef.width = this.bounds.width * this.pixelRatio;
    this.canvasRef.height = this.bounds.height * this.pixelRatio;
    this.drawOnCanvas(this.canvasRef, this.props.effectWithArrows);

    this.setState({
      position: this.bounds,
    });
  };

  drawOnCanvas = (destCanvas, withArrows = false) => {
    const sourceIsFlip = this.props.sourceIsFlip;
    const resultIsFlip = this.props.resultIsFlip;
    const isVertical = this.isVerticalEffect();
    const isInverted = this.isInvertedEffect();
    const imagesOffset = this.resultImage[isVertical ? "width" : "height"] * (this.props.imagesOffset || 0);
    const effectOffset = Math.round(this.effectOffset * this.effectMaskCanvas[isVertical ? "height" : "width"]);
    const canvas = document.createElement("canvas");
    const ow = this.resultImage.width;
    const oh = this.resultImage.height;
    const ctx = canvas.getContext("2d");

    canvas.width = ow;
    canvas.height = oh;
    canvas[isVertical ? "height" : "width"] += imagesOffset * 2;

    if (sourceIsFlip) {
      ctx.scale(-1, 1);
    }

    ctx.drawImage(
      this.sourceImage,
      (sourceIsFlip ? -ow : 0) + (isInverted ? (imagesOffset * 2) : 0),
      0,
      ow,
      oh
    );

    if (sourceIsFlip) {
      ctx.setTransform(1, 0, 0, 1, 0, 0);
    }

    ctx.globalCompositeOperation = "destination-out";
    ctx.drawImage(
      this.effectMaskCanvas,
      ((this.effectMaskCanvas.width - (isVertical ? 0 : effectOffset))/3) - (isVertical ? 0 : imagesOffset),
      ((this.effectMaskCanvas.height - (isVertical ? effectOffset : 0))/3) - (isVertical ? imagesOffset : 0),
      this.effectMaskCanvas.width/3 + ((isVertical ? 0 : imagesOffset) * 2),
      this.effectMaskCanvas.height/3 + ((isVertical ? imagesOffset : 0) * 2),
      0,
      0,
      canvas.width,
      canvas.height
    );

    ctx.globalCompositeOperation = "destination-over";

    if (resultIsFlip) {
      ctx.scale(-1, 1);
    }

    ctx.drawImage(
      this.resultImage,
      (resultIsFlip ? -ow : 0) + (isVertical ? 0 : ((isInverted ? 0 : (imagesOffset * 2)) * (resultIsFlip ? -1 : 1))),
      isVertical ? (imagesOffset * 2) : 0,
      ow,
      oh
    );

    if (resultIsFlip) {
      ctx.setTransform(1, 0, 0, 1, 0, 0);
    }

    ctx.globalCompositeOperation = "source-over";
    ctx.drawImage(
      this.effectImageCanvas,
      ((this.effectImageCanvas.width - (isVertical ? 0 : effectOffset))/3) - (isVertical ? 0 : imagesOffset),
      ((this.effectImageCanvas.height - (isVertical ? effectOffset : 0))/3) - (isVertical ? imagesOffset : 0),
      this.effectImageCanvas.width/3 + ((isVertical ? 0 : imagesOffset) * 2),
      this.effectImageCanvas.height/3 + ((isVertical ? imagesOffset : 0) * 2),
      0,
      0,
      canvas.width,
      canvas.height
    );

    if (withArrows) {
      const aw = 32;
      const ah = 32;
      const ap = 32;

      if (isVertical) {
        const ay = ((oh * this.effectEdgeForArrows) + (oh * this.effectOffset) - ap/2) + imagesOffset;
        const ax = Math.round(ow * 0.75);

        ctx.drawImage(
          this.upArrowCanvas,
          0,
          0,
          this.upArrowCanvas.width,
          this.upArrowCanvas.height,
          ax,
          ay - ap,
          aw,
          ah
        );
        ctx.drawImage(
          this.downArrowCanvas,
          0,
          0,
          this.downArrowCanvas.width,
          this.downArrowCanvas.height,
          ax,
          ay + ap,
          aw,
          ah
        );
      } else {
        const ax = ((ow * this.effectEdgeForArrows) + (ow * this.effectOffset) - ap/2) + imagesOffset;
        const ay = Math.round(oh * 0.75);

        ctx.drawImage(
          this.leftArrowCanvas,
          0,
          0,
          this.leftArrowCanvas.width,
          this.leftArrowCanvas.height,
          ax - ap,
          ay,
          aw,
          ah
        );
        ctx.drawImage(
          this.rightArrowCanvas,
          0,
          0,
          this.rightArrowCanvas.width,
          this.rightArrowCanvas.height,
          ax + ap,
          ay,
          aw,
          ah
        );
      }
    }

    ctx.setTransform(1, 0, 0, 1, 0, 0);

    const destCtx = destCanvas.getContext("2d");
    destCtx.clearRect(0, 0, destCanvas.width, destCanvas.height);

    const bounds1 = containRectToBounds(canvas, destCanvas);
    destCtx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      bounds1.x,
      bounds1.y,
      bounds1.width,
      bounds1.height
    );

    this.props.onDrawn && this.props.onDrawn({
      sourceIsFlip: !!this.props.sourceIsFlip,
      resultIsFlip: !!this.props.resultIsFlip,
      imagesOffset: this.props.imagesOffset,
      effectOffset: this.effectOffset,
    });
  };

  render() {
    const positionStyle = {
      width: this.state.position.width,
      height: this.state.position.height,
      left: this.state.position.x,
      top: this.state.position.y,
    };

    return <View.ComparatorViewHolder ref={(ref) => this.holderRef = ref}>
      <View.ComparatorViewCanvas
        ref={(ref) => this.canvasRef = ref}
        style={positionStyle}
        hidden={!this.state.isReady}
      />
    </View.ComparatorViewHolder>;
  }
}

ComparatorView.propTypes = {
  sourceImageUrl: PropTypes.string.isRequired,
  sourceIsFlip: PropTypes.bool,
  resultImageUrl: PropTypes.string.isRequired,
  resultIsFlip: PropTypes.bool,
  imagesOffset: PropTypes.number,
  effect: PropTypes.object.isRequired,
  effectAnimateOnLoad: PropTypes.bool,
  effectAnimationDuration: PropTypes.number,
  effectAnimationStrength: PropTypes.number,
  effectAnimationRepeat: PropTypes.number,
  effectWithArrows: PropTypes.bool,
  onGetResultCanvasFunc: PropTypes.func.isRequired,
  onEffectOffsetChanged: PropTypes.func,
  onReadyStateChanged: PropTypes.func,
  onDrawn: PropTypes.func,
};