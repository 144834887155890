import React from "react";
import { FlexWrapStyled } from "./FlexWrap.style";

export default class FlexWrap extends React.Component {
    render() {
        return (
            <FlexWrapStyled {...this.props}>{this.props.children}</FlexWrapStyled>
        )
    }
}
