import {isWebviewApp} from "./config.utils";
import {webviewAppIds} from "./webview";

function createSubscriptinSkuData(sku, price = "$4.99") {
  return {
    sku: sku,
    title: sku,
    description: sku,
    price: price,
    period: "P1W",
    trial: "P3D",
  };
}

export function getSubscriptionSku() {
  const subscriptionSkus = {
    toonmeIos: createSubscriptinSkuData("pro_features_tnmweek_4"),
    toonmeIosNoTrial: createSubscriptinSkuData("pro_features_tnmweek_4_nt"),
    toonmeAndroid: createSubscriptinSkuData("week_2"),
    toonmeAndroidNoTrial: createSubscriptinSkuData("week_2_nt"),
    nppIos: createSubscriptinSkuData("weekly_sub_4"),
    nppIosNoTrial: createSubscriptinSkuData("weekly_sub_4_nt"),
    nppAndroid: createSubscriptinSkuData("week_2"),
    nppAndroidNoTrial: createSubscriptinSkuData("week_2_nt"),
    hotify: createSubscriptinSkuData("hot_week_1", "$7.99"),
    hotifyNoTrial: createSubscriptinSkuData("hot_week_1_nt", "$7.99"),
    hotifyYearNoTrial: createSubscriptinSkuData("hot_year_1_nt", "$29.99"),
  };

  function choice(android, androidNoTrial, ios, iosNoTrial) {
    return window.clientConfig.isWebviewAndroid ? androidNoTrial : iosNoTrial;
  }

  if (isWebviewApp(webviewAppIds.toonme)) {
    return choice(
      subscriptionSkus.toonmeAndroid,
      subscriptionSkus.toonmeAndroidNoTrial,
      subscriptionSkus.toonmeIos,
      subscriptionSkus.toonmeIosNoTrial,
    );
  } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
    return choice(
      subscriptionSkus.nppAndroid,
      subscriptionSkus.nppAndroidNoTrial,
      subscriptionSkus.nppIos,
      subscriptionSkus.nppIosNoTrial,
    );
  } else if (isWebviewApp(webviewAppIds.hotify)) {
    return choice(
      subscriptionSkus.hotify,
      subscriptionSkus.hotifyNoTrial,
      subscriptionSkus.hotify,
      subscriptionSkus.hotifyNoTrial,
    );
  } else {
    return choice(
      subscriptionSkus.nppAndroid,
      subscriptionSkus.nppAndroidNoTrial,
      subscriptionSkus.nppIos,
      subscriptionSkus.nppIosNoTrial,
    );
  }
}

export function getInAppSku() {
  const inAppSkus = {
    toonme: createInAppSkuData("tm_avatar_pack_1"),
    newprofilepic: createInAppSkuData("npp_avatar_pack_1"),
    photolab: createInAppSkuData("pl_avatar_pack_1"),
    tooncoin: createInAppSkuData("tc_avatar_pack_1"),
    // todo hotify
  };

  function createInAppSkuData(sku, price = "$0.99") {
    return {
      sku: sku,
      title: sku,
      description: sku,
      price: price,
    };
  }

  if (isWebviewApp(webviewAppIds.toonme)) {
    return inAppSkus.toonme;
  } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
    return inAppSkus.newprofilepic;
  } else if (isWebviewApp(webviewAppIds.tooncoin)) {
    return inAppSkus.tooncoin;
  } else {
    return inAppSkus.photolab;
  }
}