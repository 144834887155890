import styled from "styled-components";

export const BtnStyled = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  font-size: 8px;
  text-align: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
  background-size: cover;
  box-sizing: border-box;
  padding: 0 6px 4px;
  margin-left: 8px;

  &.active {
    border: 2px solid rgb(255, 98, 165);
  }

  &.waiting {
    .timer-loader {
      z-index: 10;
    }
  }

  &[disabled] {
    opacity: 0.45;
  }

  &.error {
    opacity: 0.35;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    background-color: #212121;

    .error-icon {
      display: block;
      width: 24px;
      height: 24px;
      fill: #fff;
      opacity: 0.3;
    }
  }

  &.active.waiting {
    border: 0;
    box-shadow: inset 0 0 0 4px rgb(255, 98, 165);

    .timer-loader {
      box-sizing: border-box;
    }
  }

  &:first-child {
    margin-left: 6px;
  }

  &:last-child {
    margin-right: 6px;
  }

  .error-icon {
    display: none;
  }

  &.new {
    position: relative;
    overflow: hidden;
  }

  .label-pro {
    font-size: 6px;
    text-transform: uppercase;
    color: #fff;
    background-color: rgb(255, 98, 164);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    top: 1px;
    right: 2px;
  }
`;

export const LabelStyled = styled.span`
  font-size: 8px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  color: #000;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 14px;
  padding: 3px 5px;
  border-radius: 0 0 0 8px;
  box-sizing: border-box;
  background-color: #ffcf05;
`;
