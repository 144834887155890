import React from "react";
import {ContainerStyled, ThumbStyled, TrackStyled, ActiveTrackStyled, GrabContainerStyled, TrackContainerStyled} from './VerticalRangeControl.style';
import PropTypes from "prop-types";

export default class VerticalRangeControl extends React.Component {

  value = 0;
  grabContainerRef = null;
  containerRef = null;
  touches = [];

  componentDidMount() {
    this.grabContainerRef.addEventListener("mouseup", this.handleMouseUp);
    this.grabContainerRef.addEventListener("mousedown", this.handleMouseDown);
    this.grabContainerRef.addEventListener("mousemove", this.handleMouseMove);
    this.grabContainerRef.addEventListener("touchstart", this.handleTouchStart, {passive: false});
    this.grabContainerRef.addEventListener("touchend", this.handleTouchEnd, {passive: false});
    this.grabContainerRef.addEventListener("touchmove", this.handleTouchMove, {passive: false});
  }

  componentWillUnmount() {
    this.grabContainerRef.removeEventListener("mouseup", this.handleMouseUp);
    this.grabContainerRef.removeEventListener("mousedown", this.handleMouseDown);
    this.grabContainerRef.removeEventListener("mousemove", this.handleMouseMove);
    this.grabContainerRef.removeEventListener("touchstart", this.handleTouchStart);
    this.grabContainerRef.removeEventListener("touchend", this.handleTouchEnd);
    this.grabContainerRef.removeEventListener("touchmove", this.handleTouchMove);
  }

  handleMouseDown = (e) => {
    e.preventDefault();
    this.isMouseDown = e.button === 0;
  };

  handleMouseUp = (e) => {
    e.preventDefault();
    this.isMouseDown = this.isMouseDown && e.button !== 0;
  };

  handleMouseMove = (e) => {
    e.preventDefault();

    if (this.isMouseDown) {
      this.move(e.clientY);
    }
  };

  handleTouchStart = (e) => {
    e.preventDefault();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i];
      this.touches.push({
        id: touch.identifier,
        x: touch.clientX,
        y: touch.clientY,
      });
    }
  }

  handleTouchEnd = (e) => {
    e.preventDefault();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = e.changedTouches[i];
      const index = this.touches.findIndex((t) => t.id === touch.identifier);
      if (index >= 0) {
        this.touches.splice(index, 1);
      }
    }
  };

  handleTouchMove = (e) => {
    e.preventDefault();

    for (let i = 0; i < e.changedTouches.length; i++) {
      const touch = this.touches.find((t) => t.id === e.changedTouches[i].identifier);
      if (touch != null) {
        touch.x = e.changedTouches[i].clientX;
        touch.y = e.changedTouches[i].clientY;
      }
    }

    if (this.touches.length === 1) {
      const touch = this.touches.first();
      this.move(touch.y);
    }
  };

  move = (y) => {
    const rect = this.containerRef.getBoundingClientRect();

    if (y < rect.top) {
      this.value = 1;
    } else if (y > rect.top + rect.height) {
      this.value = 0;
    } else {
      this.value = 1 - ((y - rect.top) / rect.height);
    }

    this.value = Math.max(this.value, 0);
    this.value = Math.min(this.value, 1);

    const realValue = this.props.min + (this.value * (this.props.max - this.props.min));

    this.props.onChange && this.props.onChange(realValue);

    this.forceUpdate();
  };

  render() {
    const percent = (this.value * 100) + "%";

    return <GrabContainerStyled ref={(ref) => this.grabContainerRef = ref}>
      <ContainerStyled ref={(ref) => this.containerRef = ref}>
        <TrackContainerStyled>
          <TrackStyled>
            <ActiveTrackStyled style={{height: percent}} />
          </TrackStyled>
          <ThumbStyled style={{bottom: percent}} />
        </TrackContainerStyled>
      </ContainerStyled>
    </GrabContainerStyled>;
  }
}

VerticalRangeControl.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};