import React from "react";
import {ButtonStyled} from "../result/ResultPage.style";
import {assetUrl} from "../../utils/etc";
import ComparatorView from "../../components/ComparatorView";
import effects from "../../config/effects";

export default class ComparatorPage extends React.Component {

  state = {
    effect: effects.first(),
    imagesOffset: 0,
  };

  onGetResultFunc = null;

  handleEffectClick = (effect) => {
    this.setState({effect});
  };

  handleSaveButtonClick = () => {
    if (typeof this.onGetResultFunc === "function") {
      const canvas = this.onGetResultFunc();
      canvas.toBlob((data) => {
        console.log(URL.createObjectURL(data));
      });
    }
  };

  render() {
    return <div className="comparator-screen">

      <div className="comparator-selector">
        {effects.map((effect) => <div key={effect.id} onClick={() => this.handleEffectClick(effect)}>
          <img src={effect.imageUrl} width={50} alt="" />
        </div>)}
      </div>

      <ComparatorView
        sourceImageUrl={assetUrl("assets/test/original.png")}
        resultImageUrl={assetUrl("assets/test/result.png")}
        effect={this.state.effect}
        effectAnimateOnLoad={false}
        effectAnimationDuration={1200}
        effectAnimationStrength={0.2}
        effectAnimationRepeat={4}
        imagesOffset={this.state.imagesOffset/100}
        sourceIsFlip={this.state.sourceIsFlip}
        resultIsFlip={this.state.resultIsFlip}
        effectWithArrows
        onGetResultCanvasFunc={(func) => {}}
        onDrawn={(data) => console.log("onDrawn", data)}
      />

      <div style={{display: "flex", justifyContent: "center", padding: 16}}>
        <input type="checkbox" checked={this.state.sourceIsFlip} onChange={(e) => this.setState({sourceIsFlip: e.target.checked})} />
        <input type="checkbox" checked={this.state.resultIsFlip} onChange={(e) => this.setState({resultIsFlip: e.target.checked})} />
        <input
          type="range"
          min={0}
          max={25}
          value={this.state.imagesOffset}
          onChange={(e) => this.setState({imagesOffset: e.target.value})}
        />
      </div>

      <div style={{display: "flex", justifyContent: "center", padding: 16}}>
        <ButtonStyled full onClick={this.handleSaveButtonClick}>Save</ButtonStyled>
      </div>
    </div>;
  }
}