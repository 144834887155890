import styled from "styled-components";

export const ComparatorViewHolder = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
`;

export const ComparatorViewCanvas = styled.canvas`
  position: absolute;
  display: block;
`

