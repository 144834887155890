import React from "react";
import i18n from "../../../i18n";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";
import {LoadingGenderButtonStyled, LoadingGenderSelectContainer} from "./LoadingView.style";
import TouchEffect from "../../../components/TouchEffect/TouchEffect";
import ProcessingLoader from "../../../components/ProcessingLoader/ProcessingLoader";
import processingManager from "../../../photolab/ProcessingManager";
import {whenSplitInRange} from "../../../photolab/config/helpers";

const genders = {
  male: "male",
  female: "female",
  human: "human",
};

class LoadingView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
      gender: 'male'
    };
  }

  handleCancelButtonClick = () => {
    this.props.onCancel && this.props.onCancel();
  };

  render() {
    const processing = processingManager.processing;
    const creative = processing && processing.creatives.first();
    const isNewTexts = false;

    return <main className="loader">
      <button
        className="loader-cancel-button"
        onClick={this.handleCancelButtonClick}>
        {i18n.t("cancel")}
      </button>

      <ProcessingLoader
        creative={creative}
        imagesUrls={this.props.imagesUrls}
        blur={this.props.imageIsBlurred}
        processingTookSecondsApprox={20}
        texts={isNewTexts ? [
          "processing_text_4",
          "processing_text_5",
          "processing_text_6",
          "processing_text_7",
          "processing_text_8",
          "processing_text_9",
          "processing_text_10",
          "processing_text_11",
          "processing_text_12",
          "processing_text_13",
          "processing_text_14",
        ] : [
          "processing_text_1",
          "processing_text_2",
          "processing_text_3",
          "processing_text_4",
          "processing_text_5",
        ]}
        textsInterval={isNewTexts ? 10_000 : 3_000}
        minTextsIntervalShow={isNewTexts ? 7_000 : 2_000}
        stroke="rgba(17,17,17,.09)"
      />

      <LoadingGenderSelectContainer direction='column' align="center" hidden={!this.props.gender}>
        <p>{i18n.t("processing_footer_text")}</p>
        <FlexWrap justify="center" gap={16}>
          <LoadingGenderButtonStyled
            active={this.props.gender === genders.female}
            onClick={() => this.props.onGenderChanged(genders.female)}>
            {i18n.t("processing_female_button")}
            <TouchEffect />
          </LoadingGenderButtonStyled>
          <LoadingGenderButtonStyled
            active={this.props.gender === genders.male}
            onClick={() => this.props.onGenderChanged(genders.male)}>
            {i18n.t("processing_male_button")}
            <TouchEffect />
          </LoadingGenderButtonStyled>
          <LoadingGenderButtonStyled
            hidden
            active={this.props.gender === genders.human}
            onClick={() => this.props.onGenderChanged(genders.human)}>
            {i18n.t("processing_human_button")}
            <TouchEffect />
          </LoadingGenderButtonStyled>
        </FlexWrap>
      </LoadingGenderSelectContainer>
    </main>;
  }
}

export default LoadingView;