import { Link } from "react-router-dom";
import styled from "styled-components";

export const BackButtonStyled = styled(Link)`
  position: absolute;
  left: 42px;
  top: 0;
  background: transparent;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.2s ease;
`;
