let buildInfo = null;

function getBuildInfo() {
  return window.axios.get("/build.json")
    .then((res) => res.data);
}

function fetch() {
  if (!buildInfo) {
    buildInfo = getBuildInfo();
  }

  return buildInfo;
}

function destroy() {
  buildInfo = null;
}

export default {
  fetch,
  destroy
};