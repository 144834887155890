import React from "react";
import { Container, WrapperContainer } from "./TabsContainer.style";

export default class TabsContainer extends React.Component {
  render() {
    return <Container>
      <WrapperContainer>
        {this.props.children}
      </WrapperContainer>
  </Container>;
  }
}
