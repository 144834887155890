import processingManager from "../photolab/ProcessingManager";

export function runOnceByExtra(processing, extraKey, func) {
  if (!processing) {
    console.error("ProcessingHelper.runOnceByExtra 'processing' argument is " + typeof processing);
    return;
  }

  if (typeof func !== "function") {
    console.error("ProcessingHelper.runOnceByExtra 'func' argument isn't a function");
    return;
  }

  if (processing.getExtra("run_once_" + extraKey, false)) {
    return;
  }

  func(processing);
  processing.setExtra("run_once_" + extraKey, true);

  processingManager.commitProcessing();
}