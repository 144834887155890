import styled from "styled-components";

const loaderDuration = "1.2s";
const loaderIterationCount = "infinite";

export const Container = styled.div`
  position: ${(props) => props.inline ? "absolute" : "fixed"};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(16px);
  background-color: ${(props) => props.backgroundColor || "#92C9FF"};
  z-index: ${(props) => props.zIndex || 150};
`;


export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 50px;
`

export const RollerContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  &:after {
    content: "";
    top: auto;
    position: absolute;
    display: block;
    animation: shadow ${loaderDuration} ${loaderIterationCount} linear;
    bottom: 0em;
    left: 0;
    height: 4px;
    width: 12px;
    border-radius: 50%;
    background-color: #000;
  }

  @keyframes shadow {
    0% {
      opacity: .3;
      transform: translateX(35px) scale(0.25, 0.25);
    }

    8% {
      transform: translateX(9px) scale(1, 1);
    }

    20% {
      transform: translateX(0px) scale(.6, .6);
    }

    40% {
      transform: translateX(-5px) scale(.25, .25);
      opacity: 0.1;
    }

    50% {
      transform: translateX(32px) scale(.6, .6);
      opacity: 0.3;
    }

    60% {
      transform: translateX(70px) scale(1, 1);
      opacity: 0.05;
    }

    65% {
      transform: translateX(78px) scale(.6, .6);
    }

    80% {
      transform: translateX(65px) scale(.25, .25);
      opacity: 0.1;
    }

    90% {
      transform: translateX(43px) scale(.4, .4);
    }

    100% {
      transform: translateX(32px);
      opacity: 0.3;
    }
  }
`

export const RollerContainer2 = styled(RollerContainer)`
  &:after {
    animation-delay: .15s;
  }
`

export const RollerContainer3 = styled(RollerContainer)`
  &:after {
    animation-delay: .3s;
  }
`

export const Roller = styled.div`
 width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  animation-iteration-count: ${loaderIterationCount};
  animation-timing-function: linear;
  animation-duration: ${loaderDuration};

  :before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
  }
`

export const Roller1 = styled(Roller)`
  left: 0;
  animation-name: rollercoaster;
  transform: rotate(135deg);

  @keyframes rollercoaster {
    0% {
      transform: rotate(135deg);
    }

    8% {
      transform: rotate(240deg);
    }

    20% {
      transform: rotate(300deg);
    }

    40% {
      transform: rotate(380deg);
    }

    45% {
      transform: rotate(440deg);
    }

    50% {
      transform: rotate(495deg);
      opacity: 1;
    }

    50.1% {
      transform: rotate(495deg);
      opacity: 0;
    }

    100% {
      transform: rotate(495deg);
      opacity: 0;
    }
  }
`

export const Roller2 = styled(Roller)`
  right: 0;
  animation-name: rollercoaster2;
  transform: rotate(-45deg);

  @keyframes rollercoaster2 {
    0% {
      opacity: 0;
    }

    49.9% {
      opacity: 0;
    }

    50% {
      opacity: 1;
      transform: rotate(-45deg);
    }

    58% {
      transform: rotate(-160deg);
    }

    70% {
      transform: rotate(-240deg);
    }

    80% {
      transform: rotate(-300deg);
    }

    90% {
      transform: rotate(-340deg);
    }

    100% {
      transform: rotate(-405deg);
    }
  }
`

export const Roller21 = styled(Roller1)`
  animation-delay: .15s;
`

export const Roller22 = styled(Roller2)`
  animation-delay: .15s;
`

export const Roller31 = styled(Roller1)`
  animation-delay: .3s;
`

export const Roller32 = styled(Roller2)`
  animation-delay: .3s;
`
