import styled from "styled-components";
import FlexWrap from "../../components/FlexWrap/FlexWrap";

export const IndexPageStyled = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    max-height: var(--vh);
    position: relative;
    box-sizing: border-box;
    /* overflow: hidden; */
    padding: 0 0 24px;

    @media all and (max-height: 750px) and (orientation: portrait) {
      padding-top: 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: linear-gradient(to top, rgba(213, 234, 255, 1) 40%, rgba(213, 234, 255, 0) 60%, rgba(213, 234, 255, 0) 85%, rgba(213, 234, 255, 1) 95%);


      @media (max-width: 1024px) {
        background: linear-gradient(to top, rgba(213, 234, 255, 1) 40%, rgba(213, 234, 255, 0) 60%);
      }

      @media (max-height: 650px) and (orientation: portrait) {
        background: linear-gradient(to top, rgba(213, 234, 255, 1) 45%, rgba(213, 234, 255, 0) 65%);
      }

      @media (max-height: 600px) and (orientation: portrait) {
        background: linear-gradient(to top, rgba(213, 234, 255, 1) 50%, rgba(213, 234, 255, 0) 70%);
      }

      /* @media (max-height: 550px) and (orientation: portrait) {
        background: linear-gradient(to top, rgba(213, 234, 255, 1) 55%, rgba(213, 234, 255, 0) 75%);
      }

      @media (max-height: 500px) and (orientation: portrait) {
        background: linear-gradient(to top, rgba(213, 234, 255, 1) 60%, rgba(213, 234, 255, 0) 80%);
      }

      @media (max-height: 450px) and (orientation: portrait) {
        background: linear-gradient(to top, rgba(213, 234, 255, 1) 65%, rgba(213, 234, 255, 0) 85%);
      }

      @media (max-height: 400px) and (orientation: portrait) {
        background: linear-gradient(to top, rgba(213, 234, 255, 1) 70%, rgba(213, 234, 255, 0) 90%);
      } */
    }

    .webview & {
      &::after {
        @media (max-width: 1024px) {
          background: linear-gradient(to top, rgba(213, 234, 255, 1) 20%, rgba(213, 234, 255, 0) 40%);
        }

        @media (max-height: 770px) and (orientation: portrait) {
          background: linear-gradient(to top, rgba(213, 234, 255, 1) 25%, rgba(213, 234, 255, 0) 45%);
        }

        @media (max-height: 670px) and (orientation: portrait) {
          background: linear-gradient(to top, rgba(213, 234, 255, 1) 30%, rgba(213, 234, 255, 0) 50%);
        }

        @media (max-height: 570px) and (orientation: portrait) {
          background: linear-gradient(to top, rgba(213, 234, 255, 1) 35%, rgba(213, 234, 255, 0) 55%);
        }

        @media (max-height: 470px) and (orientation: portrait) {
          background: linear-gradient(to top, rgba(213, 234, 255, 1) 40%, rgba(213, 234, 255, 0) 60%);
        }
      }
    }

    .container {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;
      padding-top: 99px;
      position: relative;
      max-width: 100%;

      /* @media (max-height: 500px) {
        padding-top: 40px;
      } */
    }

    .content-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;
      position: relative;
      justify-content: flex-end;
      z-index: 1;
      padding-bottom: 12px;

      @media (max-width: 780px) {
        /* padding-bottom: 36px; */
        padding-bottom: 0;
      }

      >p {
        line-height: 1.5;
        color: rgba(34, 34, 34, 0.7);
        margin-bottom: 18px;
        text-align: center;
        max-width: 352px;
        padding: 0;

        &.webview {
          margin-bottom: 24px;
        }

        &.italic {
          font-style: italic;
        }
      }

      button.margin {
        margin-bottom: 18px;
      }
    }

    .btn-upload-foto {
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background-color: #FF62A5;
      padding: 0 12px;
      border-radius: 32px;
      border: 1.5px solid #FF62A5;
      height: 48px;
      width: 100%;
      max-width: 180px;
      margin: 0 auto;
      overflow: hidden; 
      text-transform: capitalize;
    }
`;

export const IndexPageProccessingContainerStyled = styled(FlexWrap)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  @keyframes slideOriginal {
    0% {
      background-position: 0 top;
    }

    100% {
      background-position: 4030vw top;
    }
  }

  @keyframes slideProcessed {
    0% {
      background-position: -30vw top;
    }

    100% {
      background-position: 4000vw top;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  &.load_webview,
  &.load_desktop {
    div {
      &:first-child {
        -webkit-animation: slideOriginal 600s linear infinite;
        animation: slideOriginal 600s linear infinite;

        @media (max-width: 1024px) {
          -webkit-animation: slideOriginal 300s linear infinite;
          animation: slideOriginal 300s linear infinite;
        }
      }

      &:last-child {
        -webkit-animation: slideProcessed 600s linear infinite;
        animation: slideProcessed 600s linear infinite;

        @media (max-width: 1024px) {
          -webkit-animation: slideProcessed 300s linear infinite;
          animation: slideProcessed 300s linear infinite;
        }
      }
    }
  }
`;

export const IndexPageProccessingOriginalStyled = styled.div`
  width: 100vw;
  overflow: hidden;
  height: 100%;
  z-index: 0;
  background-image: url("/assets/images/banner/original.jpg");
  background-position: 0 0;

   @media (max-width: 450px) {
    background-image: url("/assets/images/banner/original-mob.jpg");
   }
`;

export const IndexPageProccessingFinalStyled = styled(IndexPageProccessingOriginalStyled)`
  position: absolute;
  width: 70vw;
  right: 0;
  border-left: 1px solid #222;
  z-index: 1;
  background-image: url("/assets/images/banner/result.jpg");
  background-position: -30vw 0;

   @media (max-width: 450px) {
    background-image: url("/assets/images/banner/result-mob.jpg");
   }
`;

export const AppStoreLinksContainerStyled = styled(FlexWrap)`
 margin-top: 18px;

 ${props => props.withoutMargin && `
   margin: 0;
 `}
`;

export const IndexPageFooterLinksContainerStyled = styled(FlexWrap)`
  margin-top: 18px;
`;

export const AppStoreLinkStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(17, 17, 17);
  border-radius: 12px;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  max-width: 174px;
  margin-left: 12px;

  ${props => props.marginTop && `
    margin-left: 0px;
    margin-top: 12px;
  `}

  &:first-child {
    margin: 0;
  }

  svg {
    margin-right: 12px;
  }

  p {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.1px;
    margin: 0;
    text-align: left;
    color: rgb(17, 17, 17);

    &.store {
      font-weight: bold;
      font-size: 16px;
      letter-spacing: -0.16px;
    }
  }
`;

export const IndexPageFooterLinkStyled = styled.a`
  font-size: 12px;
  line-height: 1.5;
  color: rgba(34, 34, 34, 0.45);
  margin-left: 48px;

  &:first-child {
    margin-left: -16px;
  }
`;
