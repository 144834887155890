import React from "react";
import { AppHeaderStyled } from "./AppHeader.style";
import BackButton from "../BackButton/BackButton";

export default class AppHeader extends React.Component {
    render() {
        if (!window.clientConfig.isWebview) {
            return (
                <AppHeaderStyled justify='center' align='center'>
                    {this.props.canBack && <BackButton onClick={this.props.onClickBackButton} />}
                    <p>{this.props.children}</p>
                </AppHeaderStyled>
            )
        }
    }
}
