import styled from "styled-components";

export const ProcessingLoaderStyled = styled.div`
    position: absolute;
    width: calc(100% - 100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    @media (max-height: 600px) {
        .swiper-slide && {
            width: calc(100% - 50px);
        }

        transform: translate(-50%, -105px);
    }

    .loader-survey && {
        position: relative;
        top: 0;
        left: 0;
        overflow: visible;
        transform: none;
    }
`;

export const ProcessingLoaderProgressBarStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175px;
    width: 175px;
    margin: auto;

    @media (max-height: 600px) {
        width: 120px;
        height: 120px;
    }

    .loader-survey && {
        height: 156px;
        width: 156px;

        @media (max-height: 600px) {
            width: 120px;
            height: 120px;
        }
    }
`;

export const ProcessingLoaderIndicatorStyled = styled.svg`
    position: absolute;
    height: 100%;
    width: 100%;
    rotate: -90deg;

    circle {
        position: absolute;
        left: 0;
        top: 0;
        cx: 87.5px;
        cy: 87.5px;
        r: 86.5px;
        fill: none;
        stroke-width: 1;

        @media (max-height: 600px) {
            cx: 60px;
            cy: 60px;
            r: 59px;
        }

        &:last-child {
            stroke-width: 2;
            stroke: #fa94d6;
        }
    }

    .loader-survey && {
        circle {
            cx: 78px;
            cy: 78px;
            r: 77px;

            @media (max-height: 600px) {
            cx: 60px;
            cy: 60px;
            r: 59px;
        }
        }
    }
`;

export const ProcessingLoaderAvatarStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background: #fff;

    img {
        ${props => !props.contain && `
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        `}
        object-position: center;
        filter: ${props => props.blur ? "blur(2px)" : "none"};
    }
`;

export const ProcessingLoaderTextStyled = styled.p`
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    line-height: 1.5;
    color: rgba(34,34,34,.7);
    min-height: 42px;

    @media (max-height: 600px) {
        margin-top: 20px;
    }

    .loader-survey && {
        position: absolute;
        top: calc(100% + 16px);
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        margin: 0;
    }
`;