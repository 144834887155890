import styled from "styled-components";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";

export const LoadingGenderSelectContainer = styled(FlexWrap)`
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 360px;
  padding: 0 16px;

  p {
    font-size: 12px;
    line-height: 1;
    color: rgba(255, 255, 255, .7);
    margin-bottom: 16px;
  }
`;

export const LoadingGenderButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% / 3) - 4px);
  border-radius: 8px;
  border: 1px solid ${props => props.active ? "#fff" : "transparent"};
  color: ${props => props.active ? "rgb(255, 255, 255)" : "rgba(255, 255, 255, .7)"};
  background-color: rgb(30, 43, 55);
  font-size: 12px;
  font-weight: ${props => props.active ? "bold" : "regular)"};
  line-height: 2.67;
  
  .touch-effect {
    border-radius: 8px;
  }
`;
