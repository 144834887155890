import styled from "styled-components";

export const ItemStyled = styled.main`
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  transform: translateZ(0);
  will-change: transform;
  background-color: rgb(180, 215, 254);
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;

  &.processed {
    animation: none;

    img, video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: contain;
      object-position: center;
      transform: translateX(-50%) translateY(-50%) translateZ(0);
      transition: opacity 320ms;
      
      &.original {
        opacity: 0;
      }
    }

    img {
      object-fit: cover;
    }
    
    &.original-shown {
      img {
        opacity: 0;
        &.original {
          opacity: 1;
        }
      }
    }
  }

  &.failed {
    background-color: rgb(21, 32, 42);
    background-image: none;
  }

  @media all and (max-height: 710px) and (orientation: portrait) {
    margin: 0 auto;
  }

  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
`;

export const OverlayStyled = styled.div`
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const FailedStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
`;

export const FailedImageStyled = styled.img`
  position: static;
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  transform: translate(0);
`;

export const FailedTextStyled = styled.p`
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 32px;
`;

export const FailedBtnStyled = styled.button`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.24px;
  color: #234af1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  width: 112px;
  height: 32px;
  border-radius: 100px;
  background-color: rgba(35, 74, 241, 0.32);
`;

export const CreativeTabsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 694px;
  height: 54px;
  width: calc(100% + 42px);
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: 50;
  margin: 0;
  padding: 0;

  @media all and (max-height: 1050px) and (orientation: landscape) {
    max-width: 514px;
  }

  @media all and (max-height: 640px) and (orientation: landscape) {
    margin-bottom: 16px;
  }
`;

export const CreativeTabsPositionContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  @media (max-height: 1050px) and (orientation: landscape) {
    max-width: 514px;
  }
`;

export const CreativeTabsStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  max-width: max-content;
  box-sizing: border-box;
  padding: 20px 16px 10px;
  column-gap: 8px;

  button {
    width: 40px;
    height: 40px;
    margin-left: 0;
    margin-right: 0;

    &.active {
      width: 40px;
      height: 40px;
      /* box-shadow: 0 0 16px 0 #234af1; */
      border: 2px solid rgb(255, 98, 165);
    }

    &:first-child {
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        background-color: rgba(46, 47, 53, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  button {
    border-radius: 50%;
    flex-shrink: 0;
    background-size: cover;
    position: relative;
    background-color: #D5EAFF;
    border: 2px solid rgb(213, 233, 255);

    .timer-loader {
      z-index: 10;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }

  &.-hide {
    visibility: hidden;
  }

  &.-remove {
    display: none;
  }

  .new {
    position: relative;

    span {
      font-size: 5px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: #ffcf05;
      box-shadow: 0 0 8px 0 #ffcf05;
    }
  }

  @media (max-width: 720px) {
    margin: 0;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
  }
`;

export const RangeTrackStyled = styled.span`
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 9px;
  height: 2px;
  width: calc(100% - 18px);
  background-color: rgba(46, 47, 53, 1);
  border-radius: 2px; */
`

export const RangeContainerStyled = styled.div`
  position: absolute;
  top: 50%;
  right: -100px;
  background-color: rgba(46, 47, 53, 0.45);
  transform: rotate(-90deg) translateY(-50%);
  border-radius: 16px;
  height: 18px;
  width: 216px;
  display: flex;
  padding: 0px 9px;
`

export const RangeStyled = styled.input`
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  cursor: pointer;


/* Removes default focus */
&:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
&::-webkit-slider-runnable-track {
  background-color: #f4c500;
  border-radius: 2px;
  height: 2px;
}

/* slider thumb */
&::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -5px; /* Centers thumb on the track */
  background-color: #fff;
  border-radius: 50%;
  height: 12px;
  width: 12px;
}

&:focus::-webkit-slider-thumb {
  outline: 3px solid #fff;
  outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/
/* slider track */
&::-moz-range-track {
  background-color: #f4c500;
  border-radius: 2px;
  height: 2px;
}

/* slider thumb */
&::-moz-range-thumb {
  background-color: #fff;
  border: none; /*Removes extra border that FF applies*/
  border-radius: 50%;
  height: 12px;
  width: 12px;
}

&:focus::-moz-range-thumb{
  outline: 3px solid #fff;
  outline-offset: 0.125rem;
}

  /* &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: none;
  background: #f4c500;
  border-radius: 5px;
  border-top: 1px solid #f4c500;
  border-bottom: 1px solid #f4c500;
}
&::-webkit-slider-thumb {
  box-shadow: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
&:focus::-webkit-slider-runnable-track {
  background: #f4c500;
}
&::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: none;
  background: #f4c500;
  border-radius: 5px;
  border-top: 1px solid #f4c500;
  border-bottom: 1px solid #f4c500;
}
&::-moz-range-thumb {
  box-shadow: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
}
&::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
&::-ms-fill-lower {
  background: #f4c500;
  border-top: 1px solid #f4c500;
  border-bottom: 1px solid #f4c500;
  border-radius: 10px;
  box-shadow: none;
}
&::-ms-fill-upper {
  background: #f4c500;
  border-top: 1px solid #f4c500;
  border-bottom: 1px solid #f4c500;
  border-radius: 10px;
  box-shadow: none;
}
&::-ms-thumb {
  box-shadow: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
}
&:focus::-ms-fill-lower {
  background: #f4c500;
}
&:focus::-ms-fill-upper {
  background: #f4c500;
} */
`

export const BtnSoundStyled = styled.button`
  width: 40px;
  height: 40px;
  background-color: rgba(17, 17, 17, 0.45);
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
  will-change: transform;

  svg {
    width: 100%;
    height: 100%;
  }
`
